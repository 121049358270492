<template>
  <div class="main">
    <div class="back" @click="topath('/index')">
      <div>分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
      <div>分会干事</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
    </div>
    <div class="thetitle">添加分会会员</div>
    <div class="profile">
      <div class="title"><div class="infor">添加会员信息</div></div>
      <div class="row">
        <div class="titler">分会名称：</div>
        <div class="pink left20">IADGE粤港奥大湾区联盟(分会)</div>
      </div>
      <div class="row">
        <div class="titler">姓名：</div>
        <div class="input phone">
          <el-input v-model="lastname.name" placeholder=""></el-input>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">邮箱：</div>
          <div class="input">
            <el-input v-model="lastname.email" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="nation">
          <div class="titler">职位：</div>
          <div class="input">
            <el-input v-model="lastname.position" placeholder=""></el-input>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="nation">
          <div class="titler">单位：</div>
          <div class="input">
            <el-input v-model="lastname.unit" placeholder=""></el-input>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="nation">
          <div class="titler">开始日期：</div>
          <div class="input">
            <a-date-picker
              size="large"
              v-model="lastname.startdate"
              @change="getdate"
              placeholder="请选择日期"
            />
          </div>
        </div>
        <div class="city">
          <div class="titler">到期日期：</div>
          <div class="input">
            <a-date-picker
              size="large"
              v-model="lastname.enddate"
              @change="getdate1"
              placeholder="请选择日期"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler"></div>
          <div class="input">
            <a-checkbox v-model:checked="continueadd" @change="handupdate()" />
            <div class="Continue coyellow">继续添加会员</div>
          </div>
        </div>
      </div>

      <div
        class="save hoverbottom"
        @click="pushmember()"
        :class="{ click: submit }"
      >
        保存
      </div>
    </div>
  </div>
</template>


<script>
import { membersubmit } from "../api/twice";
import { ElNotification } from "element-plus";
// import dayjs from "dayjs";
export default {
  name: "Home",
  data() {
    return {
      continueadd: false,
      lastname: {
        name: "",
        email: "",
        // position: "",
        street: "",
        Other_address: "",
        nation: "",
        city: "",
        membership: "",
        officers: "",
        unit: "",
        startdate: "",
        enddate: "",
      },
    };
  },
  methods: {
    async pushmember() {
      if (!this.submit) return;
      const data = await membersubmit({
        member_name: this.lastname.name,
        member_email: this.lastname.email,
        // member_position: this.lastname.position,
        member_position_distinguish: 1,
        start_time: this.lastname.startdate,
        end_time: this.lastname.enddate,
        member_organization: this.lastname.unit,
      });
      console.log(this.continueadd, 98790);
      if (this.continueadd && data) {
        ElNotification({
          title: "Success",
          message: "分会会员添加成功!",
          type: "success",
        });
        this.lastname.name = "";
        this.lastname.email = "";
        // this.lastname.position = "";
        // this.lastname.startdate = "";
        // this.lastname.enddate = "";
        this.lastname.unit = "";
        // this.continueadd = false;
      }
      if (!this.continueadd && data) {
        ElNotification({
          title: "Success",
          message: "分会会员添加成功!",
          type: "success",
        });
        setTimeout(() => {
          this.$router.push("/member");
        }, 300);
      }

      // console.log(data, 987545);
    },
    getdate(v, model) {
      this.lastname.startdate = model;
    },
    getdate1(v, model) {
      this.lastname.enddate = model;
    },
    handupdate() {},
    topath(e) {
      console.log(e, 12345);
      this.$router.push(e);
    },
  },
  computed: {
    submit() {
      if (
        this.lastname.name &&
        this.lastname.email &&
        this.lastname.startdate &&
        this.lastname.enddate &&
        this.lastname.unit
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .profile {
    padding: 56px 60px;
    border: 2px solid #333333;
    border-radius: 40px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    color: #333;
    .save {
      width: 364px;
      margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #ccc;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;

      cursor: no-drop;
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .nation {
        display: flex;
        align-items: center;
        flex: 1;
        .Continue {
          font-size: 18px;
          font-weight: bold;
          color: #c81677;
          line-height: 21px;
          margin-left: 20px;
        }
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      //   :nth-child(1) {
      //     font-size: 18px;
      //     font-weight: bold;
      //     color: #333333;
      //     line-height: 21px;
      //     width: 126px;
      //     text-align: right;
      //   }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 163px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }
      //   :nth-child(2) {
      //     margin-left: 20px;
      //   }
      .input {
        margin-left: 20px;
        // margin-bottom: 40px;
        // width: 100%;
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        // .hint {
        //   font-size: 12px;
        //   font-weight: 400;
        //   color: #333333;
        //   line-height: 14px;
        //   margin-top: 4px;
        //   position: absolute;
        //   margin-bottom: 20px;
        //   margin-left: 20px;
        .hintsize {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          :nth-child(2) {
            margin-top: 4px;
          }
        }
        // }
      }
    }
    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
<style lang="scss">
.ant-picker-large {
  width: 100%;
  height: 64px;
  border: 2px solid #777777 !important;
  padding: 20px 20px !important;
  box-sizing: border-box;
  .anticon svg {
    width: 22px !important;
    height: 22px !important;
  }
}
.el-input__wrapper {
  height: 64px;
  box-sizing: border-box;
  padding: 20px 20px !important;
  border: 2px solid #777777 !important;
  border-radius: 8px !important;
}
.input {
  .ant-checkbox-inner {
    width: 28px !important;
    height: 28px !important;
    border-radius: 50% !important;
    border: 3px solid #d15243 !important;
    &::after {
      width: 9.71px !important;
      height: 15.2px !important;
      border: 4px solid #fff;
      border-top: 0;
      border-left: 0;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d15243 !important;
}
</style>
